import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect
} from 'react-router-dom'

import Public from './pages/Public';
import Protected from './pages/Protected';
import Login from "./pages/Login";
import AuthButton from "./components/AuthButton";
import fakeAuth from './shared/fakAuth';

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        fakeAuth.isAuthenticated === true
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: {from: props.location}
            }}/>
    )}/>
);


export default function App() {
    return (
        <Router>
            <div className="App">
                <AuthButton/>
                <ul className="App-Nav">
                    <li><Link to="/public">Public Page</Link></li>
                    <li><Link to="/protected">Protected Page</Link></li>
                </ul>
                <Route path="/public" component={Public}/>
                <Route path="/login" component={Login}/>
                <PrivateRoute path='/protected' component={Protected}/>
            </div>
        </Router>
    )
}