import {withRouter} from "react-router-dom";
import fakeAuth from "../shared/fakAuth";
import React from "react";

const AuthButton = withRouter(({history}) => (
    fakeAuth.isAuthenticated ? (
        <p>
            Welcome! <button onClick={() => {
            fakeAuth.signOut(() => history.push('/'))
        }}>Sign out</button>
        </p>
    ) : (
        <p>You are not logged in.</p>
    )
));

export default AuthButton